import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addClient } from '../../features/bookingForm/bookingFormSlice';
import Button from '../Button/Button';
import Input from '../Input/Input';
import Select from '../Select/Select';
import vectorDown from '../../assets/images/vectorDown.png';
import vectorUp from '../../assets/images/vectorUp.png';
import css from './ClientSection.module.css';

const ClientSection = () => {
  const [isClient, setIsClient] = useState(false);
  const client = useSelector(state => state.bookingForm.formData.client);
  const dispatch = useDispatch();

  const handleSectionShowAndHide = () => setIsClient(!isClient);

  const handleChange = e => {
    if (
      e.target.name === 'break_policy' ||
      e.target.name === 'ward' ||
      e.target.name === 'reporting_instruction' ||
      e.target.name === 'day_1' ||
      e.target.name === 'termination_notice' ||
      e.target.name === 'accommodation' ||
      e.target.name === 'accommodation_paid_by' ||
      e.target.name === 'expenses' ||
      e.target.name === 'expenses_paid_by'
    ) {
      if (e.target.value.length > 250) return;
    }
    dispatch(addClient({ ...client, [e.target.name]: e.target.value }));
  };

  const handleCheck = e =>
    dispatch(addClient({ ...client, [e.target.name]: e.target.checked }));

  return (
    <section>
      <Button
        type="sectionBtn"
        style={
          isClient
            ? null
            : {
                background: 'rgba(13, 85, 130, 0.2)',
                color: 'var(--secondary)',
              }
        }
        onClick={handleSectionShowAndHide}
        title="Client section"
      >
        <img
          className={css.arrow}
          alt="arrow"
          src={isClient ? vectorUp : vectorDown}
        />
      </Button>
      <div
        className={css.section}
        style={isClient ? { display: 'flex' } : { display: 'none' }}
      >
        <Input
          name="parent_name"
          value={client?.parent_name || ''}
          size="x50"
          type="text"
          placeholder="Client Hirer Legal Entity"
          onChange={handleChange}
          readOnly
          required
        />
        <Input
          name="name"
          value={client?.name || ''}
          size="x50"
          type="text"
          placeholder="Client Company Name"
          onChange={handleChange}
          readOnly
          required
        />
        <Select
          name="contact_external_id"
          value={client?.contact_external_id || ''}
          options={client?.contacts}
          size="x33"
          defaultValue="Client Contact"
          onChange={handleChange}
          required
        />
        <Select
          name="contact_number_id"
          value={client?.contact_number_id || ''}
          options={
            client?.contacts.find(
              contact => contact.external_id === client.contact_external_id,
            ).phones
          }
          size="x33"
          defaultValue="Client Contact Number"
          onChange={handleChange}
          required
        />
        <Select
          name="contact_email_id"
          value={client?.contact_email_id || ''}
          options={
            client?.contacts.find(
              contact => contact.external_id === client.contact_external_id,
            ).emails
          }
          size="x33"
          defaultValue="Client Contact Email"
          onChange={handleChange}
          required
        />
        <Select
          name="invoice_contact_external_id"
          value={client?.invoice_contact_external_id || ''}
          options={client?.invoice_contacts}
          size="x33"
          defaultValue="Invoice Contact Full Name"
          onChange={handleChange}
        />
        <Input
          name="invoice_address"
          value={client?.invoice_address || ''}
          size="x33"
          type="text"
          placeholder="Invoice Address"
          onChange={handleChange}
          readOnly
        />
        <Input
          name="site_address"
          value={client?.site_address || ''}
          size="x33"
          type="text"
          placeholder="Work Address"
          onChange={handleChange}
          readOnly
          required
        />
        <label className={css.label}>
          <input
            name="outside_ir35"
            className={css.checkbox}
            type="checkbox"
            checked={client?.outside_ir35 || false}
            onChange={handleCheck}
          />
          Outside IR35
        </label>
        <Input
          name="break_policy"
          value={client?.break_policy || ''}
          size="x25"
          type="text"
          placeholder="Break Policy"
          onChange={handleChange}
        />
        <Select
          name="portal_id"
          value={client?.portal_id || ''}
          options={client?.portals}
          size="x25"
          defaultValue="Portal"
          onChange={handleChange}
          required
        />
        <Select
          name="framework_id"
          value={client?.framework_id || ''}
          options={client?.frameworks}
          size="x25"
          defaultValue="Framework"
          onChange={handleChange}
          required
        />
        <Input
          name="ward"
          value={client?.ward || ''}
          size="x25"
          type="text"
          placeholder="Ward"
          onChange={handleChange}
        />
        <Input
          name="reporting_instruction"
          value={client?.reporting_instruction || ''}
          size="x25"
          type="text"
          placeholder="Reporting Instruction"
          onChange={handleChange}
        />
        <Input
          name="day_1"
          value={client?.day_1 || ''}
          size="x25"
          type="text"
          placeholder="Day 1 Rights"
          onChange={handleChange}
        />
        <Input
          name="termination_notice"
          value={client?.termination_notice || ''}
          size="x25"
          type="text"
          placeholder="Termination Notice"
          onChange={handleChange}
        />
        <Input
          name="accommodation"
          value={client?.accommodation || ''}
          size="x50"
          type="text"
          placeholder="Accommodation"
          onChange={handleChange}
        />
        <Input
          name="accommodation_paid_by"
          value={client?.accommodation_paid_by || ''}
          size="x50"
          type="text"
          placeholder="Paid by:"
          onChange={handleChange}
        />
        <Input
          name="expenses"
          value={client?.expenses || ''}
          size="x50"
          type="text"
          placeholder="Expenses"
          onChange={handleChange}
        />
        <Input
          name="expenses_paid_by"
          value={client?.expenses_paid_by || ''}
          size="x50"
          type="text"
          placeholder="Paid by:"
          onChange={handleChange}
        />
      </div>
      <hr className={css.hr}></hr>
    </section>
  );
};

export default ClientSection;
