import React, { useEffect, useState } from 'react';
import moment from 'moment';
import api from '../../config/api';
import sortUp from '../../assets/images/sortUp.svg';
import sortDown from '../../assets/images/sortDown.svg';
import css from './UsersPage.module.css';

const UsersPage = () => {
  const [users, setUsers] = useState([]);
  const [sortBy, setSortBy] = useState({ id: null });

  useEffect(() => {
    api
      .GET_USERS()
      .then(response =>
        setUsers(response.map(el => ({ ...el, isChecked: false }))),
      );
  }, []);

  const handleSortUsers = e => {
    const sortById = e.target.closest('th').id;

    if (sortBy.id === sortById) {
      api.GET_SORTED_USERS(sortById).then(response => setUsers(response));
      setSortBy({ id: null });
      return;
    }

    api.GET_SORTED_USERS(`-${sortById}`).then(response => setUsers(response));
    setSortBy({ id: sortById });
  };

  return (
    <main className={css.main}>
      <div className={css.tableWrapper}>
        <table className={css.table}>
          <thead>
            <tr className={css.trHead} onClick={handleSortUsers}>
              <th className={`${css.thFirst} ${css.th1}`} id="name">
                User Name
                <img
                  alt="sorting way"
                  className={css.icon}
                  src={sortBy.id === 'name' ? sortUp : sortDown}
                />
              </th>
              <th className={`${css.th} ${css.th2}`} id="email">
                Email
                <img
                  alt="sorting way"
                  className={css.icon}
                  src={sortBy.id === 'email' ? sortUp : sortDown}
                />
              </th>
              <th className={`${css.th} ${css.th3}`} id="role_name">
                Role
                <img
                  alt="sorting way"
                  className={css.icon}
                  src={sortBy.id === 'role_name' ? sortUp : sortDown}
                />
              </th>
              <th className={`${css.th} ${css.th4}`} id="position">
                Position
                <img
                  alt="sorting way"
                  className={css.icon}
                  src={sortBy.id === 'position' ? sortUp : sortDown}
                />
              </th>
              <th className={`${css.th} ${css.th3}`} id="status">
                Status
                <img
                  alt="sorting way"
                  className={css.icon}
                  src={sortBy.id === 'status' ? sortUp : sortDown}
                />
              </th>
              <th className={`${css.th} ${css.th5}`} id="last_logged_in">
                Last time logged in
                <img
                  alt="sorting way"
                  className={css.icon}
                  src={sortBy.id === 'last_logged_in' ? sortUp : sortDown}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="6">
                <div className={css.tbody}>
                  <table className={css.table}>
                    <tbody>
                      {users.map(el => (
                        <tr className={css.tr} key={el.id}>
                          <td className={`${css.tdFirst} ${css.td1}`}>
                            {el.name}
                          </td>
                          <td className={`${css.td} ${css.td2}`}>{el.email}</td>
                          <td className={`${css.td} ${css.td3}`}>
                            {el.role_name}
                          </td>
                          <td className={`${css.td} ${css.td4}`}>
                            {el.position}
                          </td>
                          <td className={`${css.td} ${css.td3}`}>
                            {el.status_name}
                          </td>
                          <td className={`${css.td} ${css.td5}`}>
                            {el.last_logged_in &&
                              moment(el.last_logged_in).format('DD/MM/YYYY')}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </main>
  );
};

export default UsersPage;
