import React from 'react';
import semicircleBottom from '../../assets/images/semicircleBottom.png';
import css from './Semicircle.module.css';

const SemicircleBottom = () => (
  <img
    alt="SemicircleBottom"
    src={semicircleBottom}
    className={css.semicircleBottom}
  />
);

export default SemicircleBottom;
