import React from 'react';
import { formDeleteSuccess, formArchivatingSuccess } from '../../helpers/notyf';
import api from '../../config/api';
import closeBtn from '../../assets/images/close-button.png';
import Button from '../Button/Button';
import Input from '../Input/Input';
import css from './Popup.module.css';

const Popup = ({ isDelete, isResume, id, onClose, users }) => {
  const handleFormArchivate = () =>
    users.map(el =>
      api.ARCHIVATE_FORM({ ids: [el.id] }).then(response => {
        response === 'OK' &&
          formArchivatingSuccess(el.applicant_name) &&
          onClose();
      }),
    );

  const handleFormDelete = () =>
    users.map(el =>
      api.DELETE_FORM(el.id).then(response => {
        response === 'OK' && formDeleteSuccess(el.applicant_name) && onClose();
      }),
    );

  const handleResume = () => {
    api.APPLICANT_RESUME(id).then(response => response === 'OK' && onClose());
  };

  return (
    <div className={css.popupOverlay}>
      <div className={css.wrapper}>
        <h1 className={css.title}>
          {isDelete || isResume ? 'Are you sure?' : 'User Details'}
        </h1>
        {isDelete || isResume ? (
          <>
            {!isResume &&
              users.map(el => (
                <div className={css.inputs} key={el.id}>
                  <Input size="x100" value={el.applicant_name} readOnly />
                </div>
              ))}
            <div className={css.submit}>
              <Button
                type={isResume ? 'bigAccent' : 'secondary'}
                onClick={isResume ? handleResume : handleFormDelete}
                title={isResume ? 'Resume' : 'Delete'}
              />
            </div>
            <Button type="cancel" onClick={onClose} title="Cancel" />
          </>
        ) : (
          <>
            {users.map(el => (
              <div className={css.inputs} key={el.id}>
                <Input size="x100" value={el.applicant_name} readOnly />
              </div>
            ))}
            <div className={css.submit}>
              <Button
                type="secondary"
                onClick={handleFormArchivate}
                title="Archivate"
              />
            </div>
            <Button type="cancel" onClick={onClose} title="Cancel" />
          </>
        )}
        <div className={css.closeBtnWrapper}>
          <div className={css.closeBtnArea} onClick={onClose}>
            <img alt="close_button" src={closeBtn} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
