import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addJob } from '../../../features/bookingForm/bookingFormSlice';
import Calendar from '../Calendar/Calendar';
import JobSectionFooter from '../JobSectionFooter';
import Input from '../../Input/Input';
import Select from '../../Select/Select';
import delete_icon from '../../../assets/images/delete.png';
import moment from 'moment';
import css from '../Standard/Standard.module.css';
import localCss from './Complex.module.css';

const Complex = () => {
  const job = useSelector(state => state.bookingForm.formData.job);
  const dispatch = useDispatch();
  const { shift_types } = useSelector(state => state.referenceBook.bookData);
  const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

  const handleChange = e =>
    dispatch(addJob({ ...job, [e.target.name]: e.target.value }));

  return (
    <div className={css.main}>
      <Calendar />
      <div className={localCss.flex1}>
        {weekDays.map(el => (
          <div key={el} className={localCss.flex2}>
            <Input
              type="text"
              custom={localCss.inputDate}
              value={moment().format('YY.MM.DD` ddd')}
              onChange={handleChange}
            />
            <img className={localCss.icon} alt="Delete" src={delete_icon} />
          </div>
        ))}
      </div>
      <div
        className={css.grid}
        style={{ gridTemplateColumns: '3fr 3fr 1fr 2fr 2fr 3fr 1fr' }}
      >
        <span className={css.header}></span>
        <span className={css.header}>Shifts</span>
        <span className={css.header}>Hours</span>
        <span className={css.header}>Start Time</span>
        <span className={css.header}>End Time</span>
        <span className={css.header}>Purchase Order</span>
        <span className={css.header}></span>
        {weekDays.map(el => (
          <Fragment key={el}>
            <Select
              options={null}
              size="x100"
              defaultValue="Options"
              onChange={handleChange}
            />
            <Select
              options={shift_types}
              size="x100"
              defaultValue="Day Shift"
              onChange={handleChange}
            />
            <Input
              size="x100"
              type="number"
              placeholder="8"
              onChange={handleChange}
            />
            <Input
              size="x100"
              type="text"
              placeholder="7:00"
              onChange={handleChange}
            />
            <Input
              size="x100"
              type="text"
              placeholder="19:00"
              onChange={handleChange}
            />
            <Input
              size="x100"
              type="text"
              placeholder="Purchase Order"
              onChange={handleChange}
            />
            <img alt="Delete" src={delete_icon} />
          </Fragment>
        ))}
      </div>
      <JobSectionFooter />
    </div>
  );
};

export default Complex;
