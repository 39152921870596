import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../config/api';
import { loginSuccess } from '../../helpers/notyf';
import Button from '../../components/Button/Button';
import css from './LoginPage.module.css';

// api.LOG_IN_DEV({ email: 'oleg.supruniuk@jointoit.com', password: 'qwerty123' });

const LoginPage = () => {
  const navigate = useNavigate();

  let access_token;

  useEffect(() => {
    const url = `?${window.location.hash.slice(1)}`;

    access_token = new URLSearchParams(url).get('access_token');

    if (access_token) {
      localStorage.setItem('MICROSOFT_ACCESS_TOKEN', access_token);
      api.LOG_IN_BY_TOKEN({ access_token: access_token }).then(response => {
        response === 'OK' &&
          loginSuccess() &&
          navigate('/list-of-forms', { replace: true });
      });
    }
  }, []);

  const handleLogin = () =>
    api.LOG_IN_LINK().then(response => window.location.assign(response));

  return (
    <main className={css.main}>
      <div className={css.wrapper}>
        {access_token ? (
          <h1 className={css.title}>Authorization</h1>
        ) : (
          <>
            <h1 className={css.title}>Log into ProMedical Portal</h1>
            <Button type="primaryBig" onClick={handleLogin} title="Log in" />
          </>
        )}
      </div>
    </main>
  );
};

export default LoginPage;
