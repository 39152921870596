import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactCalendar from 'react-calendar';
import moment from 'moment';
import prevYear from '../../../assets/images/prevYear.png';
import nextYear from '../../../assets/images/nextYear.png';
import css from './Year.module.css';
import 'react-calendar/dist/Calendar.css';

const Year = ({ isOneYear, calendarData, tileContent, onChange }) => {
  const [range, setRange] = useState();
  const [year, setYear] = useState(0);
  const [choosenPeriod, setChoosenPeriod] = useState();
  const booked_data = useSelector(
    state => state.bookingForm.formData.job?.booked_data,
  );
  const adHocDates = useSelector(state => state.bookingForm.adHocDates);
  const { type } = booked_data;
  const start_date = useSelector(
    state => state.bookingForm.formData.job?.start_date,
  );
  const end_date = useSelector(
    state => state.bookingForm.formData.job?.end_date,
  );

  useEffect(() => {
    if (start_date && end_date) {
      let start = moment()
        .year(new Date(start_date).getFullYear())
        .month(new Date(start_date).getMonth())
        .date(new Date(start_date).getDate());
      let end = moment()
        .year(new Date(end_date).getFullYear())
        .month(new Date(end_date).getMonth())
        .date(new Date(end_date).getDate());

      let periodToShow = [];

      for (
        let i = start.clone();
        i.isSameOrBefore(end, 'day');
        i.add(1, 'day')
      ) {
        periodToShow.push(i.clone());
      }
      setChoosenPeriod(periodToShow);
    }

    if (calendarData && isOneYear) {
      setRange(calendarData);

      return;
    } else if (calendarData && !isOneYear) {
      let years = [];
      calendarData.map(el => years.push(el.getFullYear()));
      years = Array.from(new Set(years));

      years = years.map(year =>
        calendarData.filter(el => el.getFullYear() === year),
      );
      setRange(years);

      return () => setYear(0);
    }

    const startOfYear = moment().startOf('year');

    let rangeArr = [];
    for (
      let i = startOfYear.clone();
      i.isSame(startOfYear, 'year');
      i.add(1, 'month')
    ) {
      rangeArr.push(i.clone()._d);
    }
    setRange(rangeArr);
  }, [calendarData, isOneYear, start_date, end_date]);

  const handlePrevYear = () => {
    setYear(year - 1);
  };

  const handleNextYear = () => {
    setYear(year + 1);
  };

  const standardTileContentStyles = ({ activeStartDate, date, view }) => {
    let day = moment(date).format('DD.MM.YY ddd');

    if (date.getDay() === 6 || date.getDay() === 0) {
      return `${
        choosenPeriod.find(el => el.format('DD.MM.YY ddd') === day) &&
        css.selectedTile
      } ${css.weekend}`;
    }

    return `${
      choosenPeriod.find(el => el.format('DD.MM.YY ddd') === day) &&
      css.selectedTile
    } ${css.monthTile}`;
  };

  const adHocTileContentStyles = ({ activeStartDate, date, view }) => {
    let day = moment(date).format('DD.MM.YY ddd');

    if (date.getDay() === 6 || date.getDay() === 0) {
      return `${
        adHocDates.find(el => moment(el.date).format('DD.MM.YY ddd') === day) &&
        css.selectedTile
      } ${css.weekend}`;
    }

    return `${
      adHocDates.find(el => moment(el.date).format('DD.MM.YY ddd') === day) &&
      css.selectedTile
    } ${css.monthTile}`;
  };

  return (
    <div>
      {isOneYear ? (
        <>
          <button className={css.yearNav}>
            <img alt="Previous Year" src={prevYear} />
          </button>
          <h2 className={css.year}>
            {calendarData?.length > 0
              ? calendarData[0].getFullYear()
              : moment().year()}
          </h2>
          <button className={css.yearNav}>
            <img alt="Next Year" src={nextYear} />
          </button>
          <div className={css.flex}>
            {range?.map(el => (
              <ReactCalendar
                key={el}
                className={css.month}
                tileClassName={
                  type === 'ad_hoc'
                    ? adHocTileContentStyles
                    : standardTileContentStyles
                }
                tileContent={tileContent}
                locale="en"
                showNeighboringMonth={false}
                navigationLabel={({ label }) => label.split(' ')[0]}
                activeStartDate={el}
                view="month"
                onChange={onChange}
              />
            ))}
          </div>
        </>
      ) : (
        range?.length > 0 && (
          <>
            <button
              className={css.yearNav}
              onClick={handlePrevYear}
              disabled={year === 0}
            >
              <img alt="Previous Year" src={prevYear} />
            </button>
            <h2 className={css.year}>{range[year][0].getFullYear()}</h2>
            <button
              className={css.yearNav}
              onClick={handleNextYear}
              disabled={year === range.length - 1}
            >
              <img alt="Next Year" src={nextYear} />
            </button>
            <div className={css.flex}>
              {range[year].map(el => (
                <ReactCalendar
                  key={el}
                  className={css.month}
                  tileClassName={
                    type === 'ad_hoc'
                      ? adHocTileContentStyles
                      : standardTileContentStyles
                  }
                  tileContent={tileContent}
                  locale="en"
                  showNeighboringMonth={false}
                  navigationLabel={({ label }) => label.split(' ')[0]}
                  activeStartDate={el}
                  view="month"
                  onChange={onChange}
                />
              ))}
            </div>
          </>
        )
      )}
    </div>
  );
};

export default Year;
