import React, { useEffect, useState } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import api from '../../config/api';
import Button from '../Button/Button';
import css from './Header.module.css';

const Header = () => {
  const [user, setUser] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.getItem('ACCESS_TOKEN') &&
      api.AUTH_ME().then(response => setUser(response));
  }, []);

  const logOutHandler = () => api.LOG_OUT().then(navigate('/login'));

  return (
    <header className={css.header}>
      <nav className={css.nav}>
        <NavLink
          to="/list-of-forms"
          className={({ isActive }) => (isActive ? css.active : css.navLink)}
        >
          Booking form
        </NavLink>
        {user.role_name && user.role_name === 'admin' && (
          <NavLink
            to="/users"
            className={({ isActive }) => (isActive ? css.active : css.navLink)}
          >
            Users
          </NavLink>
        )}
      </nav>
      <div>
        <span className={css.hi}>Hi, {user.name}</span>
        <Button type="accent" onClick={logOutHandler} title="Log out" />
      </div>
    </header>
  );
};

export default Header;
