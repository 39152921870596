import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  formData: {},
  datesToConfirm: [],
  adHocDates: [],
  confirmed: false,
  workHistory: [],
  isHoliday: false,
};

const bookingFormSlice = createSlice({
  name: 'bookingForm',
  initialState,
  reducers: {
    addFormData: (state, action) => {
      state.formData = action.payload;
    },
    addApplicant: (state, action) => {
      state.formData.applicant = action.payload;
    },
    addJob: (state, action) => {
      state.formData.job = action.payload;
    },
    addClient: (state, action) => {
      state.formData.client = action.payload;
    },
    addWorkHistory: (state, action) => {
      state.workHistory = action.payload;
    },
    changeType: (state, action) => {
      state.formData.job.booked_data.type = action.payload;
    },
    changeStartDate: (state, action) => {
      state.formData.job.start_date = action.payload;
    },
    changeEndDate: (state, action) => {
      state.formData.job.end_date = action.payload;
    },
    changePeriod: (state, action) => {
      state.formData.job.booked_data.period = action.payload;
    },
    changeRate: (state, action) => {
      state.formData.job.booked_data.pay_rates = action.payload;
    },
    addDate: (state, action) => {
      state.formData.job.booked_data.dates = action.payload;
    },
    addShift: (state, action) => {
      state.formData.job.booked_data.day_shifts = action.payload;
    },
    setDatesToConfirm: (state, action) => {
      state.datesToConfirm = action.payload;
    },
    setAdHocDates: (state, action) => {
      state.adHocDates = action.payload;
    },
    setIsConfirmed: (state, action) => {
      state.confirmed = action.payload;
    },
    setSummary: (state, action) => {
      state.formData.job.booked_data.summary = action.payload;
    },
    setIsHoliday: (state, action) => {
      state.isHoliday = action.payload;
    },
  },
});

export const {
  addFormData,
  addApplicant,
  addJob,
  addClient,
  addWorkHistory,
  changeType,
  changeStartDate,
  changeEndDate,
  changePeriod,
  changeRate,
  addDate,
  addShift,
  setDatesToConfirm,
  setAdHocDates,
  setIsConfirmed,
  setSummary,
  setIsHoliday,
} = bookingFormSlice.actions;

export default bookingFormSlice.reducer;
