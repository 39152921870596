import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  changeRate,
  addDate,
  setDatesToConfirm,
  setIsConfirmed,
  setSummary,
} from '../../features/bookingForm/bookingFormSlice';
import { confirmSuccess } from '../../helpers/notyf';
import Button from '../Button/Button';
import Input from '../Input/Input';
import ConfirmPopup from '../Popup/ConfirmPopup';
import css from './JobSection.module.css';

const JobSectionFooter = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { datesToConfirm, adHocDates, isHoliday } = useSelector(
    state => state.bookingForm,
  );
  const start_date = useSelector(
    state => state.bookingForm.formData.job?.start_date,
  );
  const end_date = useSelector(
    state => state.bookingForm.formData.job?.end_date,
  );
  const calendar_data = useSelector(
    state => state.bookingForm.formData.job?.calendar_data,
  );
  const { type, day_shifts, pay_rates, summary } = useSelector(
    state => state.bookingForm.formData.job?.booked_data,
  );
  const pay_type_external_id = useSelector(
    state => state.bookingForm.formData.job?.pay_type_external_id,
  );
  const { shift_types } = useSelector(state => state.referenceBook.bookData);
  const dispatch = useDispatch();

  const rateFormater = toChange => {
    const changedRates = pay_rates.map(rate => {
      if (toChange) {
        let rateToChange = toChange.target;
        rateToChange.value = isNaN(+rateToChange.value[0])
          ? +rateToChange.value.slice(1)
          : +rateToChange.value;

        if (rate.external_id === +rateToChange.id) {
          if (rateToChange.name === 'charge') {
            return {
              ...rate,
              [rateToChange.name]: +rateToChange.value,
              fee:
                +pay_type_external_id !== 799
                  ? +pay_type_external_id === 24
                    ? +(
                        +rateToChange.value -
                        ((+rate.rate / 100) * 15.05 + +rate.rate)
                      )
                        .toString()
                        .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                    : +(+rateToChange.value - +rate.rate)
                        .toString()
                        .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                  : +rateToChange.value,
            };
          } else if (rateToChange.name === 'rate') {
            return {
              ...rate,
              charge:
                +pay_type_external_id === 799
                  ? +rateToChange.value + +rate.fee
                  : +rate.charge,
              [rateToChange.name]: +rateToChange.value,
              fee:
                +pay_type_external_id !== 799
                  ? +pay_type_external_id === 24
                    ? +(
                        +rate.charge -
                        ((+rateToChange.value / 100) * 15.05 +
                          +rateToChange.value)
                      )
                        .toString()
                        .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                    : +(+rate.charge - +rateToChange.value)
                        .toString()
                        .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                  : +rate.charge,
            };
          } else if (rateToChange.name === 'fee') {
            return {
              ...rate,
              charge:
                +pay_type_external_id === 799
                  ? +rate.rate + +rateToChange.value
                  : +rate.charge,
              [rateToChange.name]: +rateToChange.value,
            };
          }
        }

        return rate;
      } else {
        return {
          ...rate,
          charge:
            +pay_type_external_id === 799
              ? +rate.rate + +rate.fee
              : +rate.charge,
          fee:
            +pay_type_external_id !== 799
              ? +pay_type_external_id === 24
                ? +(+rate.charge - ((+rate.rate / 100) * 15.05 + +rate.rate))
                    .toString()
                    .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                : +(+rate.charge - +rate.rate)
                    .toString()
                    .match(/^-?\d+(?:\.\d{0,2})?/)[0]
              : +rate.fee.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0],
        };
      }
    });
    dispatch(changeRate(changedRates));
  };

  useEffect(() => {
    rateFormater();
  }, [pay_type_external_id]);

  const handleConfirm = () => {
    if (type === 'standard') {
      let start = moment()
        .year(new Date(start_date).getFullYear())
        .month(new Date(start_date).getMonth())
        .date(new Date(start_date).getDate());
      let end = moment()
        .year(new Date(end_date).getFullYear())
        .month(new Date(end_date).getMonth())
        .date(new Date(end_date).getDate());
      let range = [];
      for (
        let i = start.clone();
        i.isSameOrBefore(end, 'day');
        i.add(1, 'day')
      ) {
        range.push(i.clone());
      }

      let dates = range.filter(date =>
        day_shifts.find(
          shift => shift.day_name === date.format('dddd') && shift.hours > 0,
        ),
      );
      //FIXME: some holidays can be couple days long, change .find logic (sameOrBefore -> add(1, day))
      if (!isHoliday)
        dates = dates.filter(
          date =>
            !calendar_data.bank_holiday.dates.find(
              el =>
                moment(el.start).format('DD.MM.YY') === date.format('DD.MM.YY'),
            ),
        );

      dates = dates.map(date => {
        const shift = day_shifts.find(
          shift => shift.day_name === date.format('dddd'),
        );

        const newDate = {
          ...shift,
          date: date,
          start: new Date(
            moment(date).format('YYYY-MM-DD') +
              'T' +
              moment(shift.start_time).format('HH:mm:ss'),
          ),
          end: new Date(
            `${
              +shift.shift_external_id === 28 ||
              +shift.shift_external_id === 44 ||
              +shift.shift_external_id === 39
                ? moment(date).add(1, 'day').format('YYYY-MM-DD')
                : moment(date).format('YYYY-MM-DD')
            }T` + moment(shift.end_time).format('HH:mm:ss'),
          ),
          title: shift_types.find(
            el => +el.external_id === +shift.shift_external_id,
          ).name,
        };

        return newDate;
      });

      dispatch(setDatesToConfirm(dates));
    }
    setIsPopupOpen(true);
  };

  const handleConfirmed = () => {
    dispatch(addDate([]));

    let datesToSet = [];
    let summaryToSet = {};
    let daysToSet = 0;
    let hoursToSet = 0;
    let valueToSet = 0;

    if (type === 'standard') {
      datesToSet = datesToConfirm;

      daysToSet = datesToConfirm.length;

      datesToConfirm.map(el => (hoursToSet += +el.hours));

      datesToConfirm.map(date => {
        let fee = 0;
        const payRate = pay_rates.find(
          rate => +rate.shift_external_id === +date.shift_external_id,
        );

        fee = payRate.fee;

        let hours = +date.hours - 0.5 * Math.floor(+date.hours / 6);

        valueToSet += hours * fee;
      });
    }

    if (type === 'ad_hoc') {
      datesToSet = adHocDates;

      daysToSet = adHocDates.length;

      adHocDates.map(el => (hoursToSet += +el.hours));

      adHocDates.map(date => {
        let fee = 0;
        const payRate = pay_rates.find(
          rate => +rate.shift_external_id === +date.shift_external_id,
        );

        fee = payRate.fee;

        let hours = +date.hours - 0.5 * Math.floor(+date.hours / 6);

        valueToSet += hours * fee;
      });
    }

    if (type === 'complex') {
      //logic for complex
    }

    summaryToSet.days = daysToSet;
    summaryToSet.hours = hoursToSet;
    summaryToSet.value = valueToSet;

    dispatch(setSummary(summaryToSet));
    dispatch(addDate(datesToSet));
    dispatch(setIsConfirmed(true));
    confirmSuccess();
  };

  const handlePopupClose = () => setIsPopupOpen(false);

  const handleChangeRate = e => {
    if (isNaN(+e.target.value[0])) {
      if (isNaN(+e.target.value.slice(1)) || +e.target.value.slice(1) > 999)
        return;
    } else if (+e.target.value > 999) return;

    const rateToChange = Object.assign({}, e);
    rateFormater(rateToChange);
  };

  const ratesToSort = [...pay_rates];

  const payRates = [
    pay_rates.find(el => +el.shift_external_id === 30),
    ...ratesToSort
      .filter(el => +el.shift_external_id !== 30)
      .filter(el => el.hidden === false)
      .filter(rate => {
        if (type === 'standard') {
          return day_shifts.find(
            shift => +shift.shift_external_id === +rate.shift_external_id,
          );
        }
        if (type === 'ad_hoc') {
          return adHocDates?.find(
            shift => +shift.shift_external_id === +rate.shift_external_id,
          );
        }
        //for complex tab
        return console.log('complex');
      })
      .sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }

        return 0;
      }),
  ];

  return (
    <>
      {isPopupOpen && (
        <ConfirmPopup onConfirm={handleConfirmed} onClose={handlePopupClose} />
      )}
      <div className={css.footer}>
        {payRates.map(rate => (
          <Fragment key={rate.external_id}>
            <div>
              <span className={css.footerText}>{rate.name}:</span>
            </div>
            <div>
              <span className={css.footerText}>Charge</span>
              <Input
                id={rate.external_id}
                value={`£${rate.charge}`}
                name="charge"
                type="text"
                placeholder="£0"
                size="xsm"
                onChange={handleChangeRate}
                readOnly={+pay_type_external_id === 799 && true}
                withoutAdaptivePlaceholder
              />
            </div>
            <div>
              <span className={css.footerText}>Pay Rate</span>
              <Input
                id={rate.external_id}
                value={`£${rate.rate}`}
                name="rate"
                type="text"
                placeholder="£0"
                size="xsm"
                onChange={handleChangeRate}
                withoutAdaptivePlaceholder
              />
            </div>
            <div>
              <span className={css.footerText}>Fee</span>
              <Input
                id={rate.external_id}
                value={`£${rate.fee}`}
                name="fee"
                type="text"
                placeholder="£0"
                size="xsm"
                onChange={handleChangeRate}
                readOnly={+pay_type_external_id !== 799 && true}
                withoutAdaptivePlaceholder
              />
            </div>
          </Fragment>
        ))}
      </div>
      <div className={`${css.grid} ${css.mb}`}>
        <Button type="secondary" onClick={handleConfirm} title="Confirm" />
      </div>
      <>
        <h2 className={css.footerTitle}>Summary</h2>
        <div className={css.footer2}>
          <div>
            <span className={css.footerText}>Days Booked</span>
            <span className={css.dataTag}>{summary.days}</span>
          </div>
          <div>
            <span className={css.footerText}>Hours Booked</span>
            <span className={css.dataTag}>{summary.hours}</span>
          </div>
          <div>
            <span className={css.footerText}>Estimated value</span>
            <span className={css.dataTag}>{`£${+summary.value
              .toString()
              .match(/^-?\d+(?:\.\d{0,2})?/)[0]}`}</span>
          </div>
        </div>
      </>
    </>
  );
};

export default JobSectionFooter;
