import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';

const notyf = new Notyf({
  duration: 7000,
  types: [
    {
      type: 'error',
      backgroundColor: 'grey',
      message: 'Whoops, something went wrong, please try again!',
    },
  ],
});

export const loginSuccess = () => {
  notyf.success('You have successfully authorized!');

  return true;
};

export const formDeleteSuccess = data => {
  notyf.success(`You have successfully deleted form of user ${data}!`);

  return true;
};

export const formArchivatingSuccess = data => {
  notyf.success(`You have successfully moved form of user ${data} to archive!`);

  return true;
};

export const saveAsDraftSuccess = () => {
  notyf.success(`You have successfully created draft form!`);

  return true;
};

export const createSuccess = () => {
  notyf.success(`You have successfully created new form!`);

  return true;
};

export const confirmSuccess = () => {
  notyf.success(`You have successfully confirmed and saved dates!`);

  return true;
};

export const emailSendSuccess = data => {
  notyf.success(
    `You have successfully sended email${data.length > 1 ? 's' : ''} to the ${
      data[0]
    }${data.length > 1 ? ` and ${data[1]}` : ''}!`,
  );
};

export const error = data => {
  notyf.error(data.message);
};

export const fail = () => {
  notyf.error();
};
