import React from 'react';
import css from './Input.module.css';

const Input = ({
  type,
  placeholder,
  value,
  onChange,
  onKeyDown,
  autoFocus,
  name,
  readOnly,
  size,
  custom,
  id,
  withoutAdaptivePlaceholder,
  required,
}) => (
  <div className={`${css.container} ${css[size]}`}>
    {value && !withoutAdaptivePlaceholder && (
      <span className={css.placeholder}>{placeholder}</span>
    )}
    <input
      id={id}
      name={name}
      autoFocus={autoFocus}
      type={type}
      className={`${css.input} ${required && !value ? css.required : null} ${
        size === 'xsm' ? css[size] : null
      } ${custom ? custom : ''}`}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      onKeyDown={onKeyDown}
      readOnly={readOnly}
    />
  </div>
);

export default Input;
