import React from 'react';
import css from './Button.module.css';

const Button = ({ type, style, onClick, title, children, disabled }) => (
  <button
    className={`${css.button} ${css[type]}`}
    style={style}
    onClick={onClick}
    disabled={disabled}
  >
    {title}
    {children}
  </button>
);

export default Button;
