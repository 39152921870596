import axios from 'axios';
import { error } from '../helpers/notyf';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const URL = process.env.REACT_APP_URL;

axios.defaults.withCredentials = true;

const LOG_IN_DEV = credantials =>
  axios.get(`${BASE_URL}/sanctum/csrf-cookie`).then(() =>
    axios
      .post(`${URL}/auth/login`, credantials)
      .then(response => {
        localStorage.setItem('ACCESS_TOKEN', response.data.data.access_token);
        return response.data.status;
      })
      .catch(er => {
        error(er.response.data);
        er.response.status === 401 && window.location.replace('/login');
      }),
  );

const LOG_IN_LINK = () =>
  axios.get(`${BASE_URL}/sanctum/csrf-cookie`).then(() =>
    axios
      .get(`${URL}/auth/login-link`)
      .then(response => response.data.data.link)
      .catch(er => {
        error(er.response.data);
        er.response.status === 401 && window.location.replace('/login');
      }),
  );

const LOG_IN_BY_TOKEN = credantials =>
  axios
    .post(`${URL}/auth/login-by-token`, credantials)
    .then(response => {
      localStorage.setItem('ACCESS_TOKEN', response.data.data.access_token);

      return response.data.status;
    })
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const AUTH_ME = () =>
  axios
    .get(`${URL}/auth/me`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response.data.data)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const GET_FORMS = () =>
  axios
    .get(`${URL}/booking-forms?exclude[status]=archive&sort=-id`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response.data.data.items)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const GET_FILTERED_FORMS = filter =>
  axios
    .get(`${URL}/booking-forms?filter[status]=${filter}&sort=-id`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response.data.data.items)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const GET_SORTED_FORMS = sortBy =>
  axios
    .get(`${URL}/booking-forms?exclude[status]=archive&sort=${sortBy}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response.data.data.items)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const GET_FORMS_OPTIONS = () =>
  axios
    .get(`${URL}/reference-book?only[]=booking_form_statuses`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response.data.data.booking_form_statuses)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const ARCHIVATE_FORM = data =>
  axios
    .post(`${URL}/booking-forms/actions/archivate-selected`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response.data.status)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const DELETE_FORM = id =>
  axios
    .delete(`${URL}/booking-forms/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response.data.status)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const GET_USERS = () =>
  axios
    .get(`${URL}/users`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response.data.data.items)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const GET_SORTED_USERS = sortBy =>
  axios
    .get(`${URL}/users?sort=${sortBy}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response.data.data.items)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const LOG_OUT = () =>
  axios
    .post(
      `${URL}/auth/logout`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        },
      },
    )
    .then(() => localStorage.clear())
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const GET_REF_BOOK = () =>
  axios
    .get(`${URL}/reference-book`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response.data.data)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const GET_APPLICANT = id =>
  axios
    .get(`${URL}/booking-forms/actions/get-applicant?external_id=${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response.data)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const GET_WORK_HISTORY = id =>
  axios
    .get(
      `${URL}/booking-forms/actions/get-applicant-work-history?external_id=${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        },
      },
    )
    .then(response => response.data)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const SET_AND_GET_DATA_BY_PLACEMENT_ID = (formData, id) =>
  axios
    .post(
      `${URL}/booking-forms/actions/get-job-data?placement_external_id=${id}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        },
      },
    )
    .then(response => response.data)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const SET_AND_GET_DATA_BY_JOB_ID = (formData, id) =>
  axios
    .post(
      `${URL}/booking-forms/actions/get-job-data?job_external_id=${id}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        },
      },
    )
    .then(response => response.data)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const SET_AND_GET_DATA_BY_CLIENT_ID = (formData, id) =>
  axios
    .post(
      `${URL}/booking-forms/actions/get-job-data?client_external_id=${id}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        },
      },
    )
    .then(response => response.data)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const SAVE_FORM_AS_DRAFT = formData =>
  axios
    .post(`${URL}/booking-forms/actions/save-draft`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response.data.status)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const SHOW_FORM = id =>
  axios
    .get(`${URL}/booking-forms/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response.data)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const APPLICANT_RESUME = id =>
  axios
    .post(
      `${URL}/booking-forms/actions/applicant-resume/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        },
      },
    )
    .then(response => response.data.status)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const CREATE_PLACEMENT = formData =>
  axios
    .post(`${URL}/booking-forms`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response.data.status)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const GET_DOCUMENTS = (id, only) =>
  axios
    .get(
      `${URL}/booking-forms/actions/${id}/documents${
        only ? `?only=${only}` : ''
      }`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        },
      },
    )
    .then(response => response.data)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const DOWNLOAD_DOCUMENT = (id, doc) =>
  axios
    .post(`${URL}/booking-forms/actions/${id}/documents`, doc, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
      responseType: 'blob',
    })
    .then(response => response.data)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const SEND_EMAIL = (id, data) =>
  axios
    .post(
      `${URL}/booking-forms/actions/${id}/send-email`,
      {
        ...data,
        microsoft_access_token: localStorage.getItem('MICROSOFT_ACCESS_TOKEN'),
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        },
      },
    )
    .then(response => response.data.status)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const api = {
  URL,
  LOG_IN_DEV,
  LOG_IN_LINK,
  LOG_IN_BY_TOKEN,
  AUTH_ME,
  GET_FORMS,
  GET_FILTERED_FORMS,
  GET_SORTED_FORMS,
  GET_FORMS_OPTIONS,
  ARCHIVATE_FORM,
  DELETE_FORM,
  GET_USERS,
  GET_SORTED_USERS,
  LOG_OUT,
  GET_REF_BOOK,
  GET_APPLICANT,
  GET_WORK_HISTORY,
  SET_AND_GET_DATA_BY_PLACEMENT_ID,
  SET_AND_GET_DATA_BY_JOB_ID,
  SET_AND_GET_DATA_BY_CLIENT_ID,
  SAVE_FORM_AS_DRAFT,
  SHOW_FORM,
  APPLICANT_RESUME,
  CREATE_PLACEMENT,
  GET_DOCUMENTS,
  DOWNLOAD_DOCUMENT,
  SEND_EMAIL,
};

export default api;
