import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {
  setAdHocDates,
  changeRate,
} from '../../../features/bookingForm/bookingFormSlice';
import Calendar from '../Calendar/Calendar';
import JobSectionFooter from '../JobSectionFooter';
import Input from '../../Input/Input';
import Select from '../../Select/Select';
import delete_icon from '../../../assets/images/delete.png';
import css from '../Standard/Standard.module.css';
import localCss from './AdHoc.module.css';

const AdHoc = () => {
  const [hint, setHint] = useState(false);
  const adHocDates = useSelector(state => state.bookingForm.adHocDates);
  const dates = useSelector(
    state => state.bookingForm.formData.job?.booked_data?.dates,
  );
  const pay_rates = useSelector(
    state => state.bookingForm.formData.job?.booked_data.pay_rates,
  );
  const placement_purchase_order = useSelector(
    state => state.bookingForm.formData.job?.placement_purchase_order,
  );
  const { shift_types } = useSelector(state => state.referenceBook.bookData);
  const dispatch = useDispatch();

  useEffect(() => {
    dates?.length > 0 && dispatch(setAdHocDates(dates));
  }, [dates]);

  let title = 'Day Shift';

  const options = [
    {
      key: 'all',
      title: 'Copy 1st date data for all picked dates',
    },
  ];

  const handleChange = (day, e) => {
    if (e.target.name === 'hours') {
      if (e.target.value === '') {
      } else if (+e.target.value <= 0) return;
    }

    let datesToDispatch = [...adHocDates];
    datesToDispatch = datesToDispatch.map(el => {
      if (el.date === day) {
        if (e.target.name === 'hours') {
          if (!e.target.value) return el;

          const endTime = new Date(
            `${
              +e.target.value + +moment(el.start).format('HH') > 23
                ? moment(el.start).add(1, 'day').format('YYYY-MM-DD')
                : moment(el.start).format('YYYY-MM-DD')
            }` +
              'T' +
              `${
                +e.target.value + +moment(el.start).format('HH') > 23
                  ? +e.target.value + +moment(el.start).format('HH') - 24 < 10
                    ? `0${
                        +e.target.value + +moment(el.start).format('HH') - 24
                      }`
                    : +e.target.value + +moment(el.start).format('HH') - 24
                  : +e.target.value + +moment(el.start).format('HH') < 10
                  ? `0${+e.target.value + +moment(el.start).format('HH')}`
                  : +e.target.value + +moment(el.start).format('HH')
              }:` +
              moment(el.start).format('mm:ss'),
          );

          return {
            ...el,
            [e.target.name]: e.target.value,
            end: endTime,
          };
        } else if (e.target.name === 'shift_external_id') {
          const sameDates = datesToDispatch.filter(
            date =>
              moment(date.date).format('YYYY-MM-DD') ===
              moment(day).format('YYYY-MM-DD'),
          );

          if (sameDates.length > 1) {
            const shift = sameDates.find(el => el.date !== day)
              .shift_external_id;

            if (+shift === +e.target.value || +e.target.value === 39) {
              setHint(true);
              return el;
            } else if (+e.target.value === 28 || +e.target.value === 44) {
              if (+shift === 28 || +shift === 44) {
                setHint(true);
                return el;
              }
            } else if (
              +e.target.value === 30 ||
              +e.target.value === 40 ||
              +e.target.value === 41 ||
              +e.target.value === 43
            ) {
              if (
                +shift === 30 ||
                +shift === 40 ||
                +shift === 41 ||
                +shift === 43
              ) {
                setHint(true);
                return el;
              }
            }
          }

          const startTime =
            +e.target.value === 28 || +e.target.value === 44
              ? '19:00:00'
              : +e.target.value === 39
              ? '00:00:00'
              : '07:00:00';
          const endTime =
            +e.target.value === 28 || +e.target.value === 44
              ? '07:00:00'
              : +e.target.value === 39
              ? '00:00:00'
              : '19:00:00';
          const hours = +e.target.value === 39 ? 24 : 12;

          return {
            ...el,
            [e.target.name]: e.target.value,
            start: new Date(
              moment(el.start).format('YYYY-MM-DD') + 'T' + startTime,
            ),
            end: new Date(
              `${
                +e.target.value === 28 ||
                +e.target.value === 44 ||
                +e.target.value === 39
                  ? moment(el.start).add(1, 'day').format('YYYY-MM-DD')
                  : moment(el.start).format('YYYY-MM-DD')
              }T` + endTime,
            ),
            hours: hours,
            title:
              e.target.name === 'shift_external_id'
                ? shift_types.find(el => +el.external_id === +e.target.value)
                    .name
                : title,
          };
        } else {
          return {
            ...el,
            [e.target.name]: e.target.value,
          };
        }
      }

      return el;
    });

    if (e.target.name === 'shift_external_id') {
      let newRates = [];

      if (!pay_rates.find(el => +el.shift_external_id === +e.target.value)) {
        const shiftType = shift_types.find(
          el => el.external_id === +e.target.value,
        );
        newRates = [
          ...pay_rates,
          {
            new: true,
            id: shiftType.overtime_rate_external_id,
            external_id: shiftType.overtime_rate_external_id,
            shift_external_id: shiftType.external_id,
            name: shiftType.name,
            charge: 0,
            rate: 0,
            fee: 0,
            hidden: false,
          },
        ];
      } else if (
        pay_rates.find(el => +el.shift_external_id === +e.target.value)
      ) {
        newRates = [
          ...pay_rates.map(el => {
            if (+el.shift_external_id === +e.target.value) {
              return { ...el, hidden: false };
            }
            return el;
          }),
        ];
      }

      dispatch(changeRate(newRates));
    }

    dispatch(setAdHocDates(datesToDispatch));
  };

  const handleStartTimeChange = (day, value) => {
    let datesToDispatch = [...adHocDates];
    datesToDispatch = datesToDispatch.map(el => {
      if (el.date === day) {
        if (!value) return el;

        return {
          ...el,
          start: new Date(
            moment(el.start).format('YYYY-MM-DD') +
              'T' +
              moment(value).format('HH:mm:ss'),
          ),
          end: new Date(
            `${
              +el.hours + +moment(value).format('HH') > 23
                ? moment(el.start).add(1, 'day').format('YYYY-MM-DD')
                : moment(el.start).format('YYYY-MM-DD')
            }` +
              'T' +
              `${
                +el.hours + +moment(value).format('HH') > 23
                  ? +el.hours + +moment(value).format('HH') - 24 < 10
                    ? `0${+el.hours + +moment(value).format('HH') - 24}`
                    : +el.hours + +moment(value).format('HH') - 24
                  : +el.hours + +moment(value).format('HH') < 10
                  ? `0${+el.hours + +moment(value).format('HH')}`
                  : +el.hours + +moment(value).format('HH')
              }:` +
              moment(value).format('mm:ss'),
          ),
        };
      }
      return el;
    });
    dispatch(setAdHocDates(datesToDispatch));
  };

  const handleCalendarClick = (value, e) => {
    if (
      adHocDates.filter(
        date =>
          moment(date.date).format('YYYY-MM-DD') ===
          moment(value).format('YYYY-MM-DD'),
      ).length > 1
    ) {
      setHint(true);
      return;
    }

    if (
      adHocDates.find(
        date =>
          moment(date.date).format('YYYY-MM-DD') ===
            moment(value).format('YYYY-MM-DD') &&
          (+date.shift_external_id === 30 ||
            +date.shift_external_id === 39 ||
            +date.shift_external_id === 40 ||
            +date.shift_external_id === 41 ||
            +date.shift_external_id === 43),
      )
    ) {
      setHint(true);
      return;
    }

    const startTime = '07:00:00';
    const endTime = '19:00:00';

    const datesToDispatch = [...adHocDates];
    const date = {
      date: value,
      start: new Date(moment(value).format('YYYY-MM-DD') + 'T' + startTime),
      end: new Date(moment(value).format('YYYY-MM-DD') + 'T' + endTime),
      shift_external_id: 30,
      title: 'Day Shift',
      hours: 12,
      purchase_order: null,
    };
    datesToDispatch.push(date);

    dispatch(setAdHocDates(datesToDispatch));
  };

  const handleDelete = (day, e) => {
    let datesToDispatch = [...adHocDates];
    datesToDispatch = datesToDispatch.filter(el => el.date !== day);
    dispatch(setAdHocDates(datesToDispatch));
  };

  let datesToShow = [];

  if (adHocDates?.length > 0) {
    datesToShow = [...adHocDates];
    datesToShow.sort((a, b) => a.date - b.date);
  }

  const handleDuplicate = e => {
    if (e.target.value === 'all') {
      const dateToDuplicate = datesToShow[0];
      const duplicatedDates = datesToShow.map(date => {
        return {
          ...date,
          shift_external_id: dateToDuplicate.shift_external_id,
          hours: dateToDuplicate.hours,
          start: new Date(
            moment(date.start).format('YYYY-MM-DD') +
              'T' +
              moment(dateToDuplicate.start).format('HH:mm:ss'),
          ),
          end: new Date(
            `${
              moment(dateToDuplicate.start).isBefore(dateToDuplicate.end)
                ? moment(date.start).add(1, 'day').format('YYYY-MM-DD')
                : moment(date.start).format('YYYY-MM-DD')
            }` +
              'T' +
              moment(dateToDuplicate.end).format('HH:mm:ss'),
          ),
          purchase_order: dateToDuplicate.purchase_order,
        };
      });
      dispatch(setAdHocDates(duplicatedDates));

      return;
    }
  };

  const handleCloseHint = e => {
    e.stopPropagation();
    setHint(false);
  };

  return (
    <div className={css.main}>
      <Calendar onChange={handleCalendarClick} />
      <div className={css.grid}>
        <div className={css.header}>
          {hint ? (
            <div className={localCss.hintOverlay} onClick={handleCloseHint}>
              <div className={localCss.hintWrapper}>
                <p className={localCss.hintText}>
                  This date and time is already picked!
                </p>
              </div>
            </div>
          ) : null}
        </div>
        <span className={css.header}>Shifts</span>
        <span className={css.header}>Hours</span>
        <span className={css.header}>Start Time</span>
        <span className={css.header}>End Time</span>
        <span className={css.header}>Purchase Order</span>
        <div className={css.header}>
          {datesToShow.length > 1 ? (
            <Select
              value=""
              size="duplicate"
              options={options}
              onChange={handleDuplicate}
            />
          ) : null}
        </div>
      </div>
      <div className={`${css.grid} ${localCss.scroll}`}>
        {datesToShow?.map((el, i) => (
          <Fragment key={i}>
            <Input
              name="date"
              type="text"
              custom={localCss.inputDate}
              value={moment(el.date).format('DD.MM.YY ddd') || ''}
              readOnly
            />
            <Select
              name="shift_external_id"
              value={el.shift_external_id || ''}
              options={shift_types}
              size="x100"
              defaultValue="Day Shift"
              onChange={handleChange.bind(this, el.date)}
              withoutAdaptivePlaceholder
            />
            <Input
              name="hours"
              value={el.hours || ''}
              size="x100"
              type="number"
              placeholder="8"
              onChange={handleChange.bind(this, el.date)}
              withoutAdaptivePlaceholder
            />
            <div>
              <DatePicker
                locale="en"
                className={css.inputTime}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="time"
                timeFormat="HH:mm"
                dateFormat="HH:mm"
                placeholderText="07:00"
                onChange={handleStartTimeChange.bind(this, el.date)}
                selected={(el.start && new Date(el.start)) || ''}
              />
            </div>
            <div>
              <DatePicker
                locale="en"
                className={css.inputTime}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="time"
                timeFormat="HH:mm"
                dateFormat="HH:mm"
                placeholderText="19:00"
                readOnly
                selected={(el.end && new Date(el.end)) || ''}
              />
            </div>
            <Input
              name="purchase_order"
              value={el.purchase_order || ''}
              size="x100"
              type="text"
              placeholder="Purchase Order"
              onChange={handleChange.bind(this, el.date)}
              withoutAdaptivePlaceholder
              readOnly={placement_purchase_order ? true : false}
            />
            <img
              className={css.delete}
              alt="Delete"
              src={delete_icon}
              onClick={handleDelete.bind(this, el.date)}
            />
          </Fragment>
        ))}
      </div>
      <JobSectionFooter />
    </div>
  );
};

export default AdHoc;
