import React from 'react';
import css from './Select.module.css';

const Select = ({
  options,
  value,
  name,
  onChange,
  size,
  defaultValue,
  title,
  disabled,
  withoutAdaptivePlaceholder,
  required,
}) => (
  <div
    className={`${css.container} ${
      size !== 'duplicate' ? css[size] : css.dupContainer
    }`}
  >
    {value && !withoutAdaptivePlaceholder && (
      <span
        className={`${css.placeholder} ${
          options?.length > 0 &&
          (+value === options?.find(el => el.warning)?.id ||
            +value === options?.find(el => el.warning)?.external_id ||
            +value === options?.find(el => el.warning)?.key)
            ? css.error
            : null
        }`}
      >
        {defaultValue}
      </span>
    )}
    <select
      title={title && options.find(el => el.key === value).title}
      name={name}
      className={`${css.select} ${
        (required && !value) ||
        (options?.length > 0 &&
          (+value === options?.find(el => el.warning)?.id ||
            +value === options?.find(el => el.warning)?.external_id ||
            +value === options?.find(el => el.warning)?.key))
          ? css.required
          : null
      } ${size === 'duplicate' ? css[size] : null}`}
      value={value}
      onChange={onChange}
      disabled={disabled}
    >
      {options?.length > 0 && value ? (
        options.map(el => (
          <option
            key={el.id || el.external_id || el.key}
            value={el.id || el.external_id || el.key}
          >
            {el.title || el.name || el.value}
          </option>
        ))
      ) : options?.length > 0 && !value ? (
        <>
          <option value="" className={css.dn}>
            {defaultValue}
          </option>
          {options.map(el => (
            <option
              key={el.id || el.external_id || el.key}
              value={el.id || el.external_id || el.key}
            >
              {el.title || el.name || el.value}
            </option>
          ))}
        </>
      ) : (
        <option value="" className={css.dn}>
          {defaultValue}
        </option>
      )}
    </select>
    {options?.length > 0 && options?.find(el => el.warning) ? (
      +value === options?.find(el => el.warning).id ||
      +value === options?.find(el => el.warning).external_id ||
      +value === options?.find(el => el.warning).key ? (
        <p className={css.warning}>{options?.find(el => el.warning).warning}</p>
      ) : null
    ) : null}
  </div>
);

export default Select;
