import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import api from './config/api';
import Semicircle from './components/Semicircle/Semicircle';
import SemicircleBottom from './components/Semicircle/SemicircleBottom';
import Header from './components/Header/Header';
import LoginPage from './pages/LoginPage/LoginPage';
import ListOfFormsPage from './pages/ListOfFormsPage/ListOfFormsPage';
import UsersPage from './pages/UsersPage/UsersPage';
import BookingFormPage from './pages/BookingFormPage/BookingFormPage';

const ProtectedRoute = ({ adminRoleRequired, children }) => {
  if (!localStorage.getItem('ACCESS_TOKEN')) {
    return <Navigate to="/login" replace />;
  }

  if (adminRoleRequired) {
    async function f() {
      const isAdmin = await api
        .AUTH_ME()
        .then(response => response.role_name === 'admin');

      if (isAdmin) {
        return children;
      }
      return <Navigate to="/login" replace />;
    }
    f();
  }

  return children;
};

const App = () => {
  const location = useLocation();

  return (
    <>
      <Semicircle />
      {location.pathname !== '/login' && <Header />}
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/list-of-forms"
          element={
            <ProtectedRoute>
              <ListOfFormsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/users"
          element={
            <ProtectedRoute adminRoleRequired={true}>
              <UsersPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/booking-form"
          element={
            <ProtectedRoute>
              <BookingFormPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/booking-form/:id"
          element={
            <ProtectedRoute>
              <BookingFormPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="*"
          element={
            <ProtectedRoute>
              <Navigate replace to="/list-of-forms" />
            </ProtectedRoute>
          }
        />
        <Route path="/" element={<Navigate replace to="/list-of-forms" />} />
      </Routes>
      <SemicircleBottom />
    </>
  );
};

export default App;
