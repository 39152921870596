import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import moment from 'moment';
import { add } from '../../features/referenceBook/referenceBookSlice';
import {
  addFormData,
  addJob,
  addClient,
  addWorkHistory,
  setDatesToConfirm,
  setAdHocDates,
  setIsConfirmed,
} from '../../features/bookingForm/bookingFormSlice';
import api from '../../config/api';
import ApplicantSection from '../../components/ApplicantSection/ApplicantSection';
import JobSection from '../../components/JobSection/JobSection';
import ClientSection from '../../components/ClientSection/ClientSection';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import Select from '../../components/Select/Select';
import {
  saveAsDraftSuccess,
  createSuccess,
  emailSendSuccess,
  fail,
} from '../../helpers/notyf';
import css from './BookingFormPage.module.css';

const props = {
  color: 'var(--primary)',
  height: 100,
  width: 110,
  secondaryColor: 'var(--primary)',
};

const BookingFormPage = () => {
  const [id, setId] = useState('');
  const [jobId, setJobId] = useState('');
  const [previousJob, setPreviousJob] = useState();
  const [clientId, setClientId] = useState('');
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState({ applicant: false, client: false });
  const formData = useSelector(state => state.bookingForm.formData);
  const dates = useSelector(
    state => state.bookingForm.formData?.job?.booked_data?.dates,
  );
  const pay_rates = useSelector(
    state => state.bookingForm.formData?.job?.booked_data?.pay_rates,
  );
  const workHistory = useSelector(state => state.bookingForm.workHistory);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (!params.id) {
      dispatch(addFormData({}));
      dispatch(addWorkHistory([]));
      dispatch(setDatesToConfirm([]));
      dispatch(setAdHocDates([]));
      dispatch(setIsConfirmed(false));
      setId('');

      return;
    }
    if (params.id) {
      setLoading(true);
      api
        .SHOW_FORM(params.id)
        .then(response => {
          if (response?.status && response.status === 'OK') {
            dispatch(addFormData(response.data));
            setId(response.data.applicant.external_id);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [params.id]);

  useEffect(() => {
    api.GET_REF_BOOK().then(response => dispatch(add(response)));

    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, []);

  const alertUser = e => {
    e.preventDefault();
    e.returnValue =
      'Are you sure you want to leave this page? All unsave changes will be lost!';
  };

  const handleApplicantIdChange = e => {
    if (e.target.value.length > 6 || isNaN(+e.target.value)) return;
    setId(+e.target.value);
  };

  const handleApplicantById = () => {
    setLoading(true);
    dispatch(addFormData({}));
    dispatch(addWorkHistory([]));
    dispatch(setDatesToConfirm([]));
    dispatch(setAdHocDates([]));
    dispatch(setIsConfirmed(false));
    setClientId('');
    setJobId('');
    api
      .GET_APPLICANT(id)
      .then(applicant => {
        if (applicant?.status && applicant.status === 'OK') {
          dispatch(addFormData(applicant.data));
          api.GET_WORK_HISTORY(id).then(response => {
            if (response?.status && response.status === 'OK') {
              if (!response.data) {
                return;
              }
              dispatch(addWorkHistory(response.data));
              api
                .SET_AND_GET_DATA_BY_PLACEMENT_ID(
                  { ...applicant.data },
                  response.data[0].placement_external_id,
                )
                .then(response => {
                  if (response?.status && response.status === 'OK') {
                    dispatch(addFormData(response.data));
                  }
                });
            }
          });
        }
      })
      .finally(() => setLoading(false));
  };

  const handleJobChange = e => {
    setLoading(true);
    setPreviousJob(+e.target.value);
    api
      .SET_AND_GET_DATA_BY_PLACEMENT_ID({ ...formData }, +e.target.value)
      .then(response => {
        if (response?.status && response.status === 'OK') {
          dispatch(addJob(response.data.job));
          dispatch(addClient(response.data.client));
        }
      })
      .finally(() => setLoading(false));
  };

  const handleIdInput = e => {
    if (e.target.value.length > 6 || isNaN(+e.target.value)) return;
    if (e.target.name === 'job') {
      setJobId(+e.target.value);
      setClientId('');
      return;
    }

    setClientId(+e.target.value);
    setJobId('');
  };

  const handleJobData = () => {
    if (jobId) {
      setLoading(true);
      api
        .SET_AND_GET_DATA_BY_JOB_ID({ ...formData }, jobId)
        .then(response => {
          if (response?.status && response.status === 'OK') {
            dispatch(addJob(response.data.job));
            dispatch(addClient(response.data.client));
          }
        })
        .finally(() => setLoading(false));
    }

    if (clientId) {
      setLoading(true);
      api
        .SET_AND_GET_DATA_BY_CLIENT_ID({ ...formData }, clientId)
        .then(response => {
          if (response?.status && response.status === 'OK') {
            dispatch(addClient(response.data.client));
          }
        })
        .finally(() => setLoading(false));
    }
  };

  const handleSaveDraft = () => {
    setLoading(true);
    api
      .SAVE_FORM_AS_DRAFT({ ...formData })
      .then(status => {
        status === 'OK' &&
          saveAsDraftSuccess() &&
          navigate('/list-of-forms', { replace: true });
      })
      .finally(() => setLoading(false));
  };

  const handleCreatePlacement = () => {
    setLoading(true);
    let newRates = [...pay_rates];

    newRates = newRates.map(rate => {
      if (
        dates.find(
          shift => +shift.shift_external_id === +rate.shift_external_id,
        )
      ) {
        return rate;
      }

      return { ...rate, hidden: true };
    });

    newRates = newRates.filter(rate => {
      if (!rate.new) {
        return rate;
      } else if (rate.hidden) {
        return false;
      }

      return rate;
    });

    api
      .CREATE_PLACEMENT({
        ...formData,
        job: {
          ...formData.job,
          booked_data: { ...formData.job.booked_data, pay_rates: newRates },
        },
      })
      .then(status => {
        status === 'OK' &&
          createSuccess() &&
          navigate('/list-of-forms', { replace: true });
      })
      .finally(() => setLoading(false));
  };

  const handleGeneratePDF = () => {
    const data = Object.entries(checked)
      .filter(el => el[1])
      .map(el => el[0]);

    const only = data.length < 2 && data[0];

    api.GET_DOCUMENTS(formData.id, only).then(
      res =>
        res.status === 'OK' &&
        res.data.map(doc =>
          api
            .DOWNLOAD_DOCUMENT(formData.id, { filename: doc.name })
            .then(response => {
              const url = window.URL.createObjectURL(
                new Blob([response], {
                  type: 'application/pdf;charset=utf-8',
                }),
              );
              const link = document.createElement('a');
              link.href = url;
              link.download = doc.download_name;
              document.body.appendChild(link);
              link.click();
              window.URL.revokeObjectURL(url);
              link.remove();
            }),
        ),
    );
  };

  const handleEnterApplicant = e => e.key === 'Enter' && handleApplicantById();

  const handleEnterJobData = e => e.key === 'Enter' && handleJobData();

  const handleCheck = e =>
    setChecked({ ...checked, [e.target.name]: e.target.checked });

  const handleSendEmail = () => {
    const data = Object.entries(checked)
      .filter(el => el[1])
      .map(el => el[0]);

    api
      .SEND_EMAIL(formData.id, { recipients: data })
      .then(res => res === 'OK' && emailSendSuccess(data));
  };

  return (
    <main className={css.main}>
      <header>
        <div className={css.flex1}>
          <div>
            <div className={css.inputHeader}>
              <Input
                type="text"
                placeholder="Enter Applicant ID"
                onChange={handleApplicantIdChange}
                onKeyDown={handleEnterApplicant}
                value={id || ''}
                autoFocus
                readOnly={formData.status === 'archive'}
                required
              />
            </div>
            <div className={css.btnContainer}>
              <Button
                type="secondary"
                title="Get applicant data"
                onClick={handleApplicantById}
              />
            </div>
          </div>
          {workHistory.length === 0 && (
            <Select
              size="x33"
              value={previousJob || workHistory[0]?.placement_external_id}
              options={
                workHistory.length > 0 &&
                workHistory.map(el => {
                  return {
                    key: el.placement_external_id,
                    title: el.full_description,
                  };
                })
              }
              defaultValue="Previous Applicant Job"
              onChange={handleJobChange}
              disabled={formData.status === 'archive'}
            />
          )}
          {(!formData?.status ||
            formData?.status === 'draft' ||
            formData?.status === 'error') && (
            <Button
              type={!formData?.applicant?.external_id ? 'disabled2' : 'primary'}
              title="Save as draft"
              onClick={handleSaveDraft}
              disabled={!formData?.applicant?.external_id}
            />
          )}
        </div>
        {workHistory.length > 0 && (
          <Select
            size="x70"
            title={true}
            value={previousJob || workHistory[0]?.placement_external_id}
            options={
              workHistory.length > 0 &&
              workHistory.map(el => {
                return {
                  key: el.placement_external_id,
                  title: el.full_description,
                };
              })
            }
            defaultValue="Previous Applicant Job"
            onChange={handleJobChange}
            disabled={formData.status === 'archive'}
          />
        )}
        {loading && (
          <div className={css.loader}>
            <Oval {...props} />
          </div>
        )}
        {formData?.applicant?.external_id && !loading && (
          <div className={css.flex2}>
            <div>
              <div className={css.inputHeader}>
                <Input
                  value={jobId || ''}
                  name="job"
                  type="text"
                  placeholder="Job ID"
                  onChange={handleIdInput}
                  onKeyDown={handleEnterJobData}
                  readOnly={formData.status === 'archive'}
                />
              </div>
              <Input
                value={clientId || ''}
                type="text"
                placeholder="Client ID"
                onChange={handleIdInput}
                onKeyDown={handleEnterJobData}
                readOnly={formData.status === 'archive'}
              />
            </div>
            <Button
              type="secondary"
              title="Get job data"
              onClick={handleJobData}
            />
          </div>
        )}
      </header>
      {formData?.applicant?.external_id && !loading && (
        <main className={css.mainForm}>
          <div className={css.select}>
            {formData.applicant.consultants.map((el, i) => (
              <Input
                readOnly
                key={el.external_id}
                value={`${el.first_name} ${el.last_name}`}
                size="x50"
                type="text"
                placeholder={`${i + 1}${i === 0 ? 'st' : 'nd'} RC Full Name`}
                required
              />
            ))}
          </div>
          <ApplicantSection />
          <JobSection />
          <ClientSection />
          {(!formData?.status ||
            formData?.status === 'draft' ||
            formData?.status === 'error') && (
            <div className={css.hr}>
              <Button
                type="primaryBig"
                title="Create Placement"
                onClick={handleCreatePlacement}
              />
            </div>
          )}
          {formData.placement_external_id && (
            <>
              <div className={css.flex3}>
                <Input
                  readOnly
                  value={formData.users.map(el => el.name).join(', ')}
                  size="x50"
                  type="text"
                  placeholder="User"
                />
                <Input
                  readOnly
                  value={moment(formData.created_on).format('DD/MM/YYYY HH:mm')}
                  size="x50"
                  type="text"
                  placeholder="Timestamp"
                />
                <Input
                  readOnly
                  value={formData.placement_external_id}
                  size="x50"
                  type="text"
                  placeholder="Placement ID"
                />
                <Input
                  readOnly
                  value={formData.notes}
                  size="x50"
                  type="text"
                  placeholder="Errors/Notes"
                />
              </div>
              {(formData?.document_status === 'done' ||
                formData?.document_status === 'error') && (
                <div className={css.pdf}>
                  <Button
                    title="Generate PDFs Documents"
                    type={
                      !checked.applicant && !checked.client
                        ? 'disabled'
                        : 'secondary'
                    }
                    disabled={!checked.applicant && !checked.client}
                    onClick={handleGeneratePDF}
                  />
                  <Button
                    type={
                      !checked.applicant && !checked.client
                        ? 'disabled'
                        : 'secondary'
                    }
                    title="Send e-mail"
                    disabled={!checked.applicant && !checked.client}
                    onClick={handleSendEmail}
                  />
                  <label className={css.label}>
                    <input
                      name="applicant"
                      className={css.checkbox}
                      type="checkbox"
                      checked={checked.applicant}
                      onChange={handleCheck}
                    />
                    Applicant
                  </label>
                  <label className={css.label}>
                    <input
                      name="client"
                      className={css.checkbox}
                      type="checkbox"
                      checked={checked.client}
                      onChange={handleCheck}
                    />
                    Client
                  </label>
                </div>
              )}
            </>
          )}
        </main>
      )}
    </main>
  );
};

export default BookingFormPage;
