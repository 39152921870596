import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  bookData: {},
};

const referenceBookSlice = createSlice({
  name: 'referenceBook',
  initialState,
  reducers: {
    add: (state, action) => {
      state.bookData = action.payload;
    },
  },
});

export const { add } = referenceBookSlice.actions;

export default referenceBookSlice.reducer;
