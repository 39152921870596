import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactCalendar from 'react-calendar';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import moment from 'moment';
import {
  changeStartDate,
  changeEndDate,
} from '../../../features/bookingForm/bookingFormSlice';
import Year from './Year';
import sun from '../../../assets/images/sun.png';
import moon from '../../../assets/images/moon.png';
import clock from '../../../assets/images/clock.png';
import css from './Calendar.module.css';
import 'swiper/css';
import 'react-calendar/dist/Calendar.css';

SwiperCore.use([Navigation]);
moment.updateLocale('en', { week: { dow: 1 } });
const formatShortWeekday = (locale, date) =>
  ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'][date.getDay()];

const Calendar = ({ onChange }) => {
  const [range, setRange] = useState();
  const [choosenPeriod, setChoosenPeriod] = useState();
  const [isOneYear, setIsOneYear] = useState(true);
  const [yearView, setYearView] = useState(false);
  const [calendarDates, setCalendarDates] = useState();
  const booked_data = useSelector(
    state => state.bookingForm.formData.job?.booked_data,
  );
  const { type, period } = booked_data;
  const adHocDates = useSelector(state => state.bookingForm.adHocDates);
  const calendar_data = useSelector(
    state => state.bookingForm.formData.job?.calendar_data,
  );
  const start_date = useSelector(
    state => state.bookingForm.formData.job?.start_date,
  );
  const end_date = useSelector(
    state => state.bookingForm.formData.job?.end_date,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (period !== 'default' && period !== null) {
      switch (period) {
        case 'month':
          setRange(null);

          dispatch(changeStartDate(moment().startOf('month').toDate()));
          dispatch(changeEndDate(moment().endOf('month').toDate()));

          break;
        case 'quarter':
          const quarterStart = moment()
            .quarter(moment().quarter())
            .startOf('quarter');
          const quarterEnd = moment()
            .quarter(moment().quarter())
            .endOf('quarter');

          dispatch(changeStartDate(quarterStart.toDate()));
          dispatch(changeEndDate(quarterEnd.toDate()));

          let rangeArr = [];
          for (
            let i = quarterStart.clone();
            i.isSameOrBefore(quarterEnd, 'month');
            i.add(1, 'month')
          ) {
            rangeArr.push(i.clone()._d);
          }
          setYearView(false);
          setIsOneYear(true);
          setRange(rangeArr);

          break;
        case 'year':
          setRange(null);
          setIsOneYear(true);
          setYearView(true);

          dispatch(changeStartDate(moment().startOf('year').toDate()));
          dispatch(changeEndDate(moment().endOf('year').toDate()));

          break;
        default:
          break;
      }

      return;
    }
    setRange(null);
  }, [period]);

  useEffect(() => {
    if (start_date && end_date) {
      let start = moment()
        .year(new Date(start_date).getFullYear())
        .month(new Date(start_date).getMonth())
        .date(new Date(start_date).getDate());
      let end = moment()
        .year(new Date(end_date).getFullYear())
        .month(new Date(end_date).getMonth())
        .date(new Date(end_date).getDate());
      let periodToShow = [];
      for (
        let i = start.clone();
        i.isSameOrBefore(end, 'day');
        i.add(1, 'day')
      ) {
        periodToShow.push(i.clone());
      }
      setChoosenPeriod(periodToShow);
    }

    if (period !== 'default' && period) {
      return;
    }

    if (start_date && end_date) {
      let start = moment()
        .year(new Date(start_date).getFullYear())
        .month(new Date(start_date).getMonth())
        .date(new Date(start_date).getDate());
      let end = moment()
        .year(new Date(end_date).getFullYear())
        .month(new Date(end_date).getMonth())
        .date(new Date(end_date).getDate());

      let years = [];
      let rangeArr = [];
      for (
        let i = start.clone();
        i.isSameOrBefore(end, 'month');
        i.add(1, 'month')
      ) {
        rangeArr.push(i.clone()._d);
        years.push(i.clone()._d.getFullYear());
      }
      setRange(rangeArr);
      years = Array.from(new Set(years));
      setIsOneYear(years.length === 1);
      setYearView(rangeArr.length >= 12 ? true : false);
    }
  }, [start_date, end_date]);

  useEffect(() => {
    if (calendar_data) {
      let daysArr = [];
      const keys = Object.keys(calendar_data);
      let calendarData = Object.assign({}, calendar_data);
      keys.map(key => [
        calendarData[key].dates.map(date => {
          let datesArr = [];

          let start = moment(date.start);
          let end = moment(date.end);

          for (let i = start.clone(); i.isBefore(end, 'day'); i.add(1, 'day')) {
            datesArr.push(i.clone());
          }

          for (
            let i = start.clone();
            i.isSameOrBefore(end, 'day');
            i.add(1, 'day')
          ) {
            if (calendar_data[key].color === 'orange') {
              datesArr.push(i.clone());
            }
          }

          datesArr.map(el => {
            daysArr.push({
              day: el,
              title: date.title,
              color: calendar_data[key].color,
              visible: false,
            });
          });
        }),
      ]);

      setCalendarDates(daysArr);
    }
  }, [calendar_data]);

  const tileMonthContentRenderer = ({ date, view }) => {
    const day = moment(date).format('DD.MM.YYYY');
    const dates = calendarDates?.filter(
      el => el.day.format('DD.MM.YYYY') === day,
    );

    let dotColor = '';
    let lineColor = '';

    calendarDates
      ?.filter(el => el.day.format('DD.MM.YYYY') === day)
      .map(event => {
        if (
          event.color === 'green' ||
          event.color === 'purple' ||
          event.color === 'red'
        ) {
          dotColor = event.color;
        }

        return event;
      })
      .find(event => {
        if (event.color === 'blue') {
          lineColor = event.color;
        } else if (event.color === 'orange') {
          lineColor = event.color;
        }

        return event;
      });

    return (
      <>
        <span className={`${css.border} ${css[`${lineColor}`]}`}></span>
        <div className={css.innerFlex}>
          <p className={`${css.innerCell} ${css[`${lineColor}Text`]}`}>
            {date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}
          </p>
          <span className={`${css.bigDot} ${css[dotColor]}`}></span>
        </div>
        {dates?.map(date => (
          <div
            className={css.innerCell}
            key={`${date.day.format('DD.MM.YYYY')}${date.title}${date.color}`}
          >
            <p className={css.innerCellText}>{date.title}</p>
          </div>
        ))}
      </>
    );
  };

  const getTooltipData = (day, e) => {
    e?.preventDefault();

    let dates = calendarDates.map(el => {
      return { ...el, visible: false };
    });
    setCalendarDates(dates);

    if (day) {
      dates = dates.map(el => {
        if (el.day.format('DD.MM.YYYY') === day) {
          return { ...el, visible: true };
        }
        return el;
      });

      setCalendarDates(dates);
    }
  };

  const tileMonthsContentRenderer = ({ date, view }) => {
    let day = moment(date).format('DD.MM.YYYY');
    let dotColor = '';
    let lineColor = '';

    const dates = calendarDates?.filter(
      el => el.day.format('DD.MM.YYYY') === day,
    );

    dates.map(event => {
      if (
        event.color === 'green' ||
        event.color === 'purple' ||
        event.color === 'red'
      ) {
        dotColor = event.color;
      }

      if (event.color === 'orange' || event.color === 'blue') {
        lineColor = event.color;
      }

      return event;
    });

    let dateData = {};
    if (dates?.length > 0) {
      dateData = {
        weekday: dates[0]?.day.format('dddd'),
        day: dates[0]?.day.format('D'),
      };
    }

    return (
      <div className={css.outTT}>
        <div
          className={css.inTT}
          onContextMenu={getTooltipData.bind(this, day)}
        >
          <span className={`${css.dot} ${css[dotColor]}`}></span>
          {date.getDate()}
          <span className={`${css.line} ${css[lineColor]}`}></span>
          <div
            className={`${css.tooltip} ${
              dates[0]?.visible ? css.TTvisible : null
            }`}
          >
            <p className={css.tooltipWeekday}>{dateData.weekday}</p>
            <p className={css.tooltipDay}>{dateData.day}</p>
            <hr></hr>
            <ul className={css.tooltipList}>
              {dates?.map(el => (
                <li className={css.tooltipListItem} key={el.title}>
                  <div className={`${css.tooltipColor} ${css[el.color]}`}></div>
                  <p className={css.tooltipTitle}>{el.title}</p>
                </li>
              ))}
            </ul>
            <div className={css.TTarrow}></div>
          </div>
        </div>
      </div>
    );
  };

  const standardTileContentStyles = ({ activeStartDate, date, view }) => {
    let day = moment(date).format('DD.MM.YY ddd');

    if (date.getDay() === 6 || date.getDay() === 0) {
      return `${
        choosenPeriod.find(el => el.format('DD.MM.YY ddd') === day) &&
        css.noNavSelectedTile
      } ${css.weekend}`;
    }

    return `${
      choosenPeriod.find(el => el.format('DD.MM.YY ddd') === day) &&
      css.noNavSelectedTile
    } ${css.noNavTile}`;
  };

  const adHocTileContentStyles = ({ activeStartDate, date, view }) => {
    let day = moment(date).format('DD.MM.YY ddd');
    if (date.getDay() === 6 || date.getDay() === 0) {
      return `${
        adHocDates.find(el => moment(el.date).format('DD.MM.YY ddd') === day) &&
        css.noNavSelectedTile
      } ${css.weekend}`;
    }

    return `${
      adHocDates.find(el => moment(el.date).format('DD.MM.YY ddd') === day) &&
      css.noNavSelectedTile
    } ${css.noNavTile}`;
  };

  const monthStandardTileContentStyles = ({ activeStartDate, date, view }) => {
    const day = moment(date).format('DD.MM.YY ddd');
    if (date.getDay() === 6 || date.getDay() === 0) {
      return `${
        choosenPeriod?.find(el => el.format('DD.MM.YY ddd') === day) &&
        css.monthViewSelectedTile
      } ${css.monthViewWeekend}`;
    }

    return `${
      choosenPeriod?.find(el => el.format('DD.MM.YY ddd') === day) &&
      css.monthViewSelectedTile
    } ${css.monthViewTile}`;
  };

  const monthAdHocTileContentStyles = ({ activeStartDate, date, view }) => {
    let day = moment(date).format('DD.MM.YY ddd');
    if (date.getDay() === 6 || date.getDay() === 0) {
      return `${
        adHocDates.find(el => moment(el.date).format('DD.MM.YY ddd') === day) &&
        css.monthViewSelectedTile
      } ${css.monthViewWeekend}`;
    }

    return `${
      adHocDates.find(el => moment(el.date).format('DD.MM.YY ddd') === day) &&
      css.monthViewSelectedTile
    } ${css.monthViewTile}`;
  };

  return (
    <main className={css.main} onClick={getTooltipData}>
      {period === 'month' && (
        <ReactCalendar
          className={css.monthView}
          tileClassName={
            type === 'ad_hoc'
              ? monthAdHocTileContentStyles
              : monthStandardTileContentStyles
          }
          tileContent={tileMonthContentRenderer}
          locale="en"
          showFixedNumberOfWeeks
          activeStartDate={moment().toDate()}
          view="month"
          onChange={onChange}
        />
      )}
      {range?.length === 1 && (
        <ReactCalendar
          className={css.monthView}
          tileClassName={
            type === 'ad_hoc'
              ? monthAdHocTileContentStyles
              : monthStandardTileContentStyles
          }
          tileContent={tileMonthContentRenderer}
          locale="en"
          showFixedNumberOfWeeks
          activeStartDate={new Date(start_date)}
          view="month"
          onChange={onChange}
        />
      )}
      {range?.length > 1 && range?.length < 12 && isOneYear && !yearView && (
        <Swiper
          tag="section"
          wrapperTag="ul"
          navigation
          spaceBetween={30}
          slidesPerView={range.length === 2 ? 2 : 3}
          style={range.length === 2 ? { width: '60%' } : { width: '82%' }}
          className={css.swiper}
        >
          {range.map(el => (
            <SwiperSlide tag="li" key={el}>
              <ReactCalendar
                className={css.noNav}
                tileClassName={
                  type === 'ad_hoc'
                    ? adHocTileContentStyles
                    : standardTileContentStyles
                }
                tileContent={tileMonthsContentRenderer}
                locale="en"
                showFixedNumberOfWeeks
                formatShortWeekday={formatShortWeekday}
                activeStartDate={el}
                view="month"
                onChange={onChange}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      {period === 'year' && (
        <Year
          isOneYear={isOneYear}
          tileContent={tileMonthsContentRenderer}
          calendarDates={calendarDates}
          onChange={onChange}
        />
      )}
      {range?.length === 12 && isOneYear ? (
        <Year
          isOneYear={isOneYear}
          calendarData={range}
          tileContent={tileMonthsContentRenderer}
          calendarDates={calendarDates}
          onChange={onChange}
        />
      ) : range?.length > 0 && !isOneYear ? (
        <Year
          isOneYear={isOneYear}
          calendarData={range}
          tileContent={tileMonthsContentRenderer}
          calendarDates={calendarDates}
          onChange={onChange}
        />
      ) : null}

      {!range && (period === 'default' || period === null) && (
        <h1 className={css.title}>
          Please enter start date and end date or choose period above!
        </h1>
      )}
    </main>
  );
};

export default Calendar;
