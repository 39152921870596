import { configureStore } from '@reduxjs/toolkit';
import bookingFormReducer from '../features/bookingForm/bookingFormSlice';
import referenceBookReducer from '../features/referenceBook/referenceBookSlice';

export const store = configureStore({
  reducer: {
    bookingForm: bookingFormReducer,
    referenceBook: referenceBookReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
